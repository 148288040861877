export enum JobStatusEnum {
  Pending = "pending",
  Executing = "executing",
  Finished = "finished",
  Failed = "failed",
}

export enum JobTypeEnum {
  GenStructPred = "pro_struct_pred", // Protein Structure Prediction
  AbStructPred = "ab_struct_pred", // Antibody Structure Prediction
  DevPred = "dev_pred", // Characterization Developability Prediction
  AbAgDocking = "ab_ag_docking", // Antibody-Antigen Docking
  AbNumbering = "ab_numbering",
  HumanPred = "humanness_pred",
  AffinityPred = "ab_aff_pred",
  Humanization = "humanization",
  AffinityOptim = "aff_optim",
  DevOptim = "dev_optim",
  DnAbDesign = "dn_ab_design",
  AbLightChainRetrieval = "ab_lightchain_retrieval",
  ProteinMpnn = "pro_mpnn",
  OptimizationAntibody = "optimization_antibody",
  ProteinStructureAlign = "protein_structure_align",
  MHC2BindPredict = "MHC2_bind_predict",
  InterfaceViewer = "interface_viewer",
  EpitopePredict = "epitope_predict",
  TcrStructPred = "tcr_struct_pred",
  NanobodyStructPred = "nanobody_struct_pred",
  EnzymeOptimize = "enzyme_optimize",
  EnzymeMining = "enzyme_mining",
  EnzymeOptimizeTrain = "enzyme_optimize_train",
}

export enum JobGroupEnum {
  Utils = "utils",
  AntibodyDesign = "antibody_design",
  AffinityOptimization = "affinity_optimization",
  StructureModeling = "structure_modeling",
  ProteinDesign = "protein_design",
}
