import { SourceEnum } from "@services/file/enum/file.enum";
import { PropertyModel } from "@services/editor/model/editor.model";
import {
  RecommendModelEnum,
  ScoreModelEnum,
  MutationModelEnum,
  AdvisorEnum,
} from "@services/editor/enum/editor.enum";
import {
  LabelValueModel,
  NameValueModel,
} from "@services/common/model/global.model";

export const PARAMS_MAP_FN = {
  [SourceEnum.Pdb]: (id: string) => ({
    source: {
      name: "pdb",
      params: {
        provider: {
          id,
          server: { name: "rcsb", params: { encoding: "cif" } },
        },
        options: {
          type: { name: "auto", params: {} },
          representation: "preset-structure-representation-viewer-auto",
          representationParams: {},
        },
      },
    },
  }),
  [SourceEnum.Afdb]: (id: string) => ({
    source: {
      name: "alphafolddb",
      params: {
        id,
        options: {
          type: { name: "auto", params: {} },
          representation: "preset-structure-representation-viewer-auto",
          representationParams: {},
        },
      },
    },
  }),
  [SourceEnum.Upload]: (files: File[]) => {
    return {
      files: files.map((file) => {
        return {
          kind: "file",
          id: "",
          name: file.name,
          file: file,
        };
      }),
      format: { name: "auto", params: {} },
      visuals: true,
    };
  },
};

export const PROPERTIES_MAP: Record<string, LabelValueModel[]> = {
  stability: [
    {
      id: "Tm",
      label: "Fab Tm by DSF (°C)",
      value: "Tm",
    },
    {
      id: "SAS",
      label: "Accelerated Stability SEC Slope",
      value: "SAS",
    },
  ],
  expression: [
    {
      id: "HEK",
      label: "HEK Titer (mg/L)",
      value: "HEK",
    },
    {
      id: "Yeast",
      label: "Normalized Yeast Surface Expression (%)",
      value: "Yeast",
    },
  ],
  solubility: [
    {
      id: "HIC",
      label: "HIC Retention Time (Min)",
      value: "HIC",
    },
    {
      id: "SGAC",
      label: "SGAC-SINS AS100 ((NH4)2SO4 mM)",
      value: "SGAC",
    },
    {
      id: "SMAC",
      label: "SMAC Retention Time (Min)",
      value: "SMAC",
    },
    {
      id: "AC",
      label: "AC-SINS ∆λmax (nm)",
      value: "AC",
    },
    {
      id: "CSI",
      label: "CSI-BLI Delta Response (nm)",
      value: "CSI",
    },
  ],
  viscosity: [
    {
      id: "Viscosity",
      label: "Viscosity at 150 mg/mL (cP)",
      value: "Viscosity",
    },
  ],
  polyspecificity: [
    {
      id: "PSR",
      label: "PSR SMP Score (0-1)",
      value: "PSR",
    },
    {
      id: "BVP",
      label: "BVP ELISA",
      value: "BVP",
    },
    {
      id: "CIC",
      label: "CIC Retention Time (Min)",
      value: "CIC",
    },
    {
      id: "ELISA",
      label: "ELISA",
      value: "ELISA",
    },
    {
      id: "Immunogenicity",
      label: "Immunogenicity (%)",
      value: "Immunogenicity",
    },
  ],
};

export const PROPERTIES_PLUS_LIST: LabelValueModel[] = [
  {
    id: "Tm",
    label: "Fab Tm by DSF (°C)",
    value: "Tm",
  },
  {
    id: "HEK",
    label: "HEK Titer (mg/L)",
    value: "HEK",
  },
  {
    id: "HIC",
    label: "HIC Retention Time (Min)",
    value: "HIC",
  },
  {
    id: "SAS",
    label: "Accelerated Stability SEC Slope",
    value: "SAS",
  },
  {
    id: "AC",
    label: "AC-SINS ∆λmax (nm)",
    value: "AC",
  },
  {
    id: "Viscosity",
    label: "Viscosity at 150 mg/mL (cP)",
    value: "Viscosity",
  },
  {
    id: "SMAC",
    label: "SMAC Retention Time (Min)",
    value: "SMAC",
  },
];

export const propertyTree: PropertyModel[] = [
  {
    label: "All",
    value: "all",
    children: Object.keys(PROPERTIES_MAP).map((key) => {
      return {
        label: `${key[0].toUpperCase()}${key.slice(1)}`,
        value: key,
        children: PROPERTIES_MAP[key].map((item) => {
          return {
            label: item.label,
            value: item.value,
          };
        }),
      };
    }),
  },
];

export const PROPERTY_PLUS_TREE: PropertyModel[] = [
  {
    label: "All",
    value: "all",
    children: PROPERTIES_PLUS_LIST,
  },
];

export const SEQUENCE_COLOR_THEME: Record<string, Record<string, string>> = {
  Clustal: {
    A: "#80a0f0",
    R: "#f01505",
    N: "#00ff00",
    D: "#c048c0",
    C: "#f08080",
    Q: "#00ff00",
    E: "#c048c0",
    G: "#f09048",
    H: "#15a4a4",
    I: "#80a0f0",
    L: "#80a0f0",
    K: "#f01505",
    M: "#80a0f0",
    F: "#80a0f0",
    P: "#ffff00",
    S: "#00ff00",
    T: "#00ff00",
    W: "#80a0f0",
    Y: "#15a4a4",
    V: "#80a0f0",
  },
  Zappo: {
    A: "#ffafaf",
    R: "#6464ff",
    N: "#00ff00",
    D: "#ff0000",
    C: "#ffff00",
    Q: "#00ff00",
    E: "#ff0000",
    G: "#ff00ff",
    H: "#6464ff",
    I: "#ffafaf",
    L: "#ffafaf",
    K: "#6464ff",
    M: "#ffafaf",
    F: "#ffc800",
    P: "#ff00ff",
    S: "#00ff00",
    T: "#00ff00",
    W: "#ffc800",
    Y: "#ffc800",
    V: "#ffafaf",
  },
  Taylor: {
    A: "#ccff00",
    R: "#0000ff",
    N: "#cc00ff",
    D: "#ff0000",
    C: "#ffff00",
    Q: "#ff00cc",
    E: "#ff0066",
    G: "#ff9900",
    H: "#0066ff",
    I: "#66ff00",
    L: "#33ff00",
    K: "#6600ff",
    M: "#00ff00",
    F: "#00ff66",
    P: "#ffcc00",
    S: "#ff3300",
    T: "#ff6600",
    W: "#00ccff",
    Y: "#00ffcc",
    V: "#99ff00",
  },
  "gecos Flower": {
    A: "#b18a51",
    R: "#83bff1",
    N: "#0bcec6",
    D: "#01a578",
    C: "#ff5701",
    Q: "#7295ae",
    E: "#2da0a1",
    G: "#b1c23c",
    H: "#0194f9",
    I: "#f27663",
    L: "#df6e75",
    K: "#7fc3d7",
    M: "#fe9daf",
    F: "#fa559d",
    P: "#4fa32a",
    S: "#b4bd9b",
    T: "#d2b576",
    W: "#ff2ded",
    Y: "#c96ecf",
    V: "#fd997b",
  },
  "gecos Blossom": {
    A: "#8bc4b4",
    R: "#fc9502",
    N: "#b5c206",
    D: "#5fa505",
    C: "#0893fe",
    Q: "#bf8527",
    E: "#dbb501",
    G: "#00d382",
    H: "#ff5701",
    I: "#9abaf3",
    L: "#cda5dc",
    K: "#fea527",
    M: "#f5a1b8",
    F: "#f74fa8",
    P: "#10d631",
    S: "#7e9d59",
    T: "#00a29c",
    W: "#fe08fb",
    Y: "#ff4e7a",
    V: "#87c0e4",
  },
  "gecos Sunset": {
    A: "#fea0fd",
    R: "#85746a",
    N: "#abc8f5",
    D: "#2e7bbe",
    C: "#fc0cfe",
    Q: "#8c6e81",
    E: "#677892",
    G: "#2799ff",
    H: "#dbc58e",
    I: "#fa21a1",
    L: "#e01e82",
    K: "#debecc",
    M: "#d13e7b",
    F: "#ff385d",
    P: "#5766f9",
    S: "#e7b4fd",
    T: "#a658b7",
    W: "#ff3701",
    Y: "#cb5339",
    V: "#fe51b8",
  },
  "gecos Ocean": {
    A: "#c6ca9b",
    R: "#0ca0a8",
    N: "#0adfc3",
    D: "#4cdfa1",
    C: "#c68136",
    Q: "#8bd3d1",
    E: "#60dac9",
    G: "#33a551",
    H: "#00cffe",
    I: "#f2baaa",
    L: "#bb8a83",
    K: "#40a090",
    M: "#a48b88",
    F: "#ab88ae",
    P: "#afd365",
    S: "#6d9b74",
    T: "#8d9566",
    W: "#758aee",
    Y: "#bac3fc",
    V: "#e9bea4",
  },
  Hydrophobicity: {
    A: "#ad0052",
    R: "#0000ff",
    N: "#0c00f3",
    D: "#0c00f3",
    C: "#c2003d",
    Q: "#0c00f3",
    E: "#0c00f3",
    G: "#6a0095",
    H: "#1500ea",
    I: "#ff0000",
    L: "#ea0015",
    K: "#0000ff",
    M: "#b0004f",
    F: "#cb0034",
    P: "#4600b9",
    S: "#5e00a1",
    T: "#61009e",
    W: "#5b00a4",
    Y: "#4f00b0",
    V: "#f60009",
    B: "#0c00f3",
    X: "#680097",
    Z: "#0c00f3",
  },
  "Helix Propensity": {
    A: "#e718e7",
    R: "#6f906f",
    N: "#1be41b",
    D: "#778877",
    C: "#23dc23",
    Q: "#926d92",
    E: "#ff00ff",
    G: "#00ff00",
    H: "#758a75",
    I: "#8a758a",
    L: "#ae51ae",
    K: "#a05fa0",
    M: "#ef10ef",
    F: "#986798",
    P: "#00ff00",
    S: "#36c936",
    T: "#47b847",
    W: "#8a758a",
    Y: "#21de21",
    V: "#857a85",
    B: "#49b649",
    X: "#758a75",
    Z: "#c936c9",
  },
  "Strand Propensity": {
    A: "#5858a7",
    R: "#6b6b94",
    N: "#64649b",
    D: "#2121de",
    C: "#9d9d62",
    Q: "#8c8c73",
    E: "#0000ff",
    G: "#4949b6",
    H: "#60609f",
    I: "#ecec13",
    L: "#b2b24d",
    K: "#4747b8",
    M: "#82827d",
    F: "#c2c23d",
    P: "#2323dc",
    S: "#4949b6",
    T: "#9d9d62",
    W: "#c0c03f",
    Y: "#d3d32c",
    V: "#ffff00",
    B: "#4343bc",
    X: "#797986",
    Z: "#4747b8",
  },
  "Turn Propensity": {
    A: "#2cd3d3",
    R: "#708f8f",
    N: "#ff0000",
    D: "#e81717",
    C: "#a85757",
    Q: "#3fc0c0",
    E: "#778888",
    G: "#ff0000",
    H: "#708f8f",
    I: "#00ffff",
    L: "#1ce3e3",
    K: "#7e8181",
    M: "#1ee1e1",
    F: "#1ee1e1",
    P: "#f60909",
    S: "#e11e1e",
    T: "#738c8c",
    W: "#738c8c",
    Y: "#9d6262",
    V: "#07f8f8",
    B: "#f30c0c",
    X: "#7c8383",
    Z: "#5ba4a4",
  },
  "Buried Index": {
    A: "#00a35c",
    R: "#00fc03",
    N: "#00eb14",
    D: "#00eb14",
    C: "#0000ff",
    Q: "#00f10e",
    E: "#00f10e",
    G: "#009d62",
    H: "#00d52a",
    I: "#0054ab",
    L: "#007b84",
    K: "#00ff00",
    M: "#009768",
    F: "#008778",
    P: "#00e01f",
    S: "#00d52a",
    T: "#00db24",
    W: "#00a857",
    Y: "#00e619",
    V: "#005fa0",
    B: "#00eb14",
    X: "#00b649",
    Z: "#00f10e",
  },
};

export const HIGLIGHT_COLOR: string[] = [
  "#FFF9F9",
  "#FEF1F1",
  "#FDE2E2",
  "#FFD4D4",
  "#FBBFBC",
  "#FFADA9",
  "#F98E8B",
  "#FF6D69",
  "#F54A45",
];

export const RESIDUE_COLOR: number[] = [
  0xfff9f9, 0xfef1f1, 0xfde2e2, 0xffd4d4, 0xfbbfbc, 0xffada9, 0xf98e8b,
  0xff6d69, 0xf54a45,
];

export const HUMANIZATION_OPTIONS: Record<string, string[]> = {
  vh_germlines: [
    "auto",
    "IGHV1",
    "IGHV1-18",
    "IGHV1-2",
    "IGHV1-24",
    "IGHV1-3",
    "IGHV1-38-4",
    "IGHV1-45",
    "IGHV1-46",
    "IGHV1-58",
    "IGHV1-68",
    "IGHV1-69",
    "IGHV1-69-2",
    "IGHV1-69D",
    "IGHV1-8",
    "IGHV1-NL1",
    "IGHV1/OR15-1",
    "IGHV1/OR15-2",
    "IGHV1/OR15-3",
    "IGHV1/OR15-4",
    "IGHV1/OR15-5",
    "IGHV1/OR15-9",
    "IGHV1/OR21-1",
    "IGHV2",
    "IGHV2-10",
    "IGHV2-26",
    "IGHV2-5",
    "IGHV2-70",
    "IGHV2-70D",
    "IGHV2/OR16-5",
    "IGHV3",
    "IGHV3-11",
    "IGHV3-13",
    "IGHV3-15",
    "IGHV3-16",
    "IGHV3-19",
    "IGHV3-20",
    "IGHV3-21",
    "IGHV3-22",
    "IGHV3-23",
    "IGHV3-23D",
    "IGHV3-25",
    "IGHV3-29",
    "IGHV3-30",
    "IGHV3-30-2",
    "IGHV3-30-22",
    "IGHV3-30-3",
    "IGHV3-30-33",
    "IGHV3-30-42",
    "IGHV3-30-5",
    "IGHV3-30-52",
    "IGHV3-32",
    "IGHV3-33",
    "IGHV3-33-2",
    "IGHV3-35",
    "IGHV3-38",
    "IGHV3-38-3",
    "IGHV3-41",
    "IGHV3-43",
    "IGHV3-43D",
    "IGHV3-47",
    "IGHV3-48",
    "IGHV3-49",
    "IGHV3-52",
    "IGHV3-53",
    "IGHV3-54",
    "IGHV3-62",
    "IGHV3-63",
    "IGHV3-64",
    "IGHV3-64D",
    "IGHV3-66",
    "IGHV3-69-1",
    "IGHV3-7",
    "IGHV3-71",
    "IGHV3-72",
    "IGHV3-73",
    "IGHV3-74",
    "IGHV3-9",
    "IGHV3-NL1",
    "IGHV3/OR15-7",
    "IGHV3/OR16-10",
    "IGHV3/OR16-12",
    "IGHV3/OR16-13",
    "IGHV3/OR16-14",
    "IGHV3/OR16-15",
    "IGHV3/OR16-16",
    "IGHV3/OR16-17",
    "IGHV3/OR16-18",
    "IGHV3/OR16-20",
    "IGHV3/OR16-6",
    "IGHV3/OR16-8",
    "IGHV3/OR16-9",
    "IGHV4",
    "IGHV4-28",
    "IGHV4-30-2",
    "IGHV4-30-4",
    "IGHV4-31",
    "IGHV4-34",
    "IGHV4-38-2",
    "IGHV4-39",
    "IGHV4-4",
    "IGHV4-55",
    "IGHV4-59",
    "IGHV4-61",
    "IGHV4/OR15-8",
    "IGHV5",
    "IGHV5-10-1",
    "IGHV5-51",
    "IGHV5-78",
    "IGHV6",
    "IGHV6-1",
    "IGHV7",
    "IGHV7-34-1",
    "IGHV7-4-1",
    "IGHV7-40",
    "IGHV7-81",
    "IGHV8",
    "IGHV8-51-1",
  ],
  vl_germlines: [
    "auto",
    "IGKV",
    "IGKV1",
    "IGKV1-12",
    "IGKV1-13",
    "IGKV1-16",
    "IGKV1-17",
    "IGKV1-27",
    "IGKV1-33",
    "IGKV1-37",
    "IGKV1-39",
    "IGKV1-5",
    "IGKV1-6",
    "IGKV1-8",
    "IGKV1-9",
    "IGKV1-NL1",
    "IGKV1/OR-2",
    "IGKV1/OR-3",
    "IGKV1/OR-4",
    "IGKV1/OR1-1",
    "IGKV1/OR10-1",
    "IGKV1/OR15-118",
    "IGKV1/OR2-0",
    "IGKV1/OR2-1",
    "IGKV1/OR2-108",
    "IGKV1/OR2-11",
    "IGKV1/OR2-118",
    "IGKV1/OR2-2",
    "IGKV1/OR2-3",
    "IGKV1/OR2-9",
    "IGKV1/OR22-5",
    "IGKV1/OR9-1",
    "IGKV1/OR9-2",
    "IGKV1/ORY-1",
    "IGKV1D",
    "IGKV1D-12",
    "IGKV1D-13",
    "IGKV1D-16",
    "IGKV1D-17",
    "IGKV1D-33",
    "IGKV1D-37",
    "IGKV1D-39",
    "IGKV1D-42",
    "IGKV1D-43",
    "IGKV1D-8",
    "IGKV2",
    "IGKV2-18",
    "IGKV2-24",
    "IGKV2-28",
    "IGKV2-29",
    "IGKV2-30",
    "IGKV2-4",
    "IGKV2-40",
    "IGKV2/OR2-7D",
    "IGKV2/OR22-4",
    "IGKV2D",
    "IGKV2D-18",
    "IGKV2D-24",
    "IGKV2D-26",
    "IGKV2D-28",
    "IGKV2D-29",
    "IGKV2D-30",
    "IGKV2D-40",
    "IGKV3",
    "IGKV3-11",
    "IGKV3-15",
    "IGKV3-20",
    "IGKV3-7",
    "IGKV3/OR2-268",
    "IGKV3D",
    "IGKV3D-11",
    "IGKV3D-15",
    "IGKV3D-20",
    "IGKV3D-7",
    "IGKV4",
    "IGKV4-1",
    "IGKV5",
    "IGKV5-2",
    "IGKV6",
    "IGKV6-21",
    "IGKV6D",
    "IGKV6D-21",
    "IGKV6D-41",
    "IGKV7",
    "IGKV7-3",
    "IGLV",
    "IGLV1",
    "IGLV1-36",
    "IGLV1-40",
    "IGLV1-41",
    "IGLV1-44",
    "IGLV1-47",
    "IGLV1-50",
    "IGLV1-51",
    "IGLV1-62",
    "IGLV10",
    "IGLV10-54",
    "IGLV11",
    "IGLV11-55",
    "IGLV2",
    "IGLV2-11",
    "IGLV2-14",
    "IGLV2-18",
    "IGLV2-23",
    "IGLV2-33",
    "IGLV2-34",
    "IGLV2-5",
    "IGLV2-8",
    "IGLV3",
    "IGLV3-1",
    "IGLV3-10",
    "IGLV3-12",
    "IGLV3-13",
    "IGLV3-16",
    "IGLV3-19",
    "IGLV3-21",
    "IGLV3-22",
    "IGLV3-25",
    "IGLV3-27",
    "IGLV3-31",
    "IGLV3-32",
    "IGLV3-9",
    "IGLV4",
    "IGLV4-3",
    "IGLV4-60",
    "IGLV4-69",
    "IGLV5",
    "IGLV5-37",
    "IGLV5-39",
    "IGLV5-45",
    "IGLV5-48",
    "IGLV5-52",
    "IGLV6",
    "IGLV6-57",
    "IGLV7",
    "IGLV7-43",
    "IGLV7-46",
    "IGLV8",
    "IGLV8-61",
    "IGLV8/OR8-1",
    "IGLV9",
    "IGLV9-49",
  ],
};

export const SCORE_MODEL_MAP = {
  [ScoreModelEnum.GeoHumanness]: "GeoHumanness",
  [ScoreModelEnum.SideChainPKa]: "Side-chain pKa",
  [ScoreModelEnum.Charge]: "Charge",
  [ScoreModelEnum.ChargedPatch]: "Charged patch",
  [ScoreModelEnum.Hydrophobicity]: "Hydrophobicity",
  [ScoreModelEnum.HydrophobicPatch]: "Hydrophobic patch",
  [ScoreModelEnum.PTM]: "PTM",
};

export const RECOMMEND_MODEL_MAP = {
  [RecommendModelEnum.ESM23B]: "ESM2-3B",
  [RecommendModelEnum.ESM1V650M]: "ESM1v-650M",
  [RecommendModelEnum.GeoHumAb]: "GeoHumAb",
  // [RecommendModelEnum.GermlineSequences]: "Germline Sequences",
};

export const MUTATION_MODEL_MAP = {
  [MutationModelEnum.Geohumab]: "GeoHumAb",
  [MutationModelEnum.CdrGrafting]: "CDR Grafting",
  [MutationModelEnum.OneClickHumanization]: "One-click humanization",
};

export const ADVISOR_MAP = {
  [AdvisorEnum.AiCopilot]: "AI copilot",
  [AdvisorEnum.GermlineSequence]: "Germline sequence",
};

export const RECOMMEND_MODEL_OPTIONS: NameValueModel[] = [
  {
    name: RECOMMEND_MODEL_MAP[RecommendModelEnum.ESM23B],
    value: RecommendModelEnum.ESM23B,
  },
  {
    name: RECOMMEND_MODEL_MAP[RecommendModelEnum.ESM1V650M],
    value: RecommendModelEnum.ESM1V650M,
  },
  {
    name: RECOMMEND_MODEL_MAP[RecommendModelEnum.GeoHumAb],
    value: RecommendModelEnum.GeoHumAb,
  },
  // {
  //   name: RECOMMEND_MODEL_MAP[RecommendModelEnum.GermlineSequences],
  //   value: RecommendModelEnum.GermlineSequences,
  // },
];

export const ADVISOR_OPTIONS: NameValueModel[] = [
  {
    name: ADVISOR_MAP[AdvisorEnum.AiCopilot],
    value: AdvisorEnum.AiCopilot,
  },
  {
    name: ADVISOR_MAP[AdvisorEnum.GermlineSequence],
    value: AdvisorEnum.GermlineSequence,
  },
];

export const MUTATION_MODEL_OPTIONS: NameValueModel[] = [
  {
    name: MUTATION_MODEL_MAP[MutationModelEnum.Geohumab],
    value: MutationModelEnum.Geohumab,
  },
  {
    name: MUTATION_MODEL_MAP[MutationModelEnum.CdrGrafting],
    value: MutationModelEnum.CdrGrafting,
  },
  {
    name: MUTATION_MODEL_MAP[MutationModelEnum.OneClickHumanization],
    value: MutationModelEnum.OneClickHumanization,
  },
];
