import React, { useEffect, useState, useRef } from "react";
import {
  Layout,
  Nav,
  Button,
  Avatar,
  Dropdown,
  Divider,
  Popover,
  Tooltip,
  Notification,
  Typography,
} from "@douyinfe/semi-ui";
import { useRequest } from "ahooks";
import { useNavigate } from "react-router-dom";
import { ReactComponent as NotificationsIcon } from "@assets/icons/notifications.svg";
import { ReactComponent as SupportIcon } from "@assets/icons/support.svg";
import styles from "./header.module.css";
import { logoutApi } from "@services/login/login.api";
import { getLoginUserInfo } from "@services/user-manage/user.api";
import { removeLocalStorage, getLocalStorage } from "@utils/localstorage";
import { LoginEnum } from "@services/login/enum/login.enum";
import logoImage from "@assets/logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@state/store";
import { encrypt } from "@utils/encryption.util";
import { JobTypeEnum, JobGroupEnum } from "@services/job/enum/job.enum";
import {
  getNotificationInfoApi,
  cancelNotificationRedDotApi,
  markNotificationReadApi,
} from "@services/notification/notification.api";
import { downloadFileUrlApi } from "@services/file/file.api";
import {
  JOB_TYPE_LABEL,
  JOB_GROUP_LABEL,
} from "@services/job/config/job.config";
import { deleteUser } from "@state/global/reducer";
import NotificationScrollList from "@components/notification-scroll-list";
import {
  setJobPanelVisible,
  setSelectJobType,
} from "@state/protein-editor/reducer";
import { TokenModel } from "@services/login/model/login.model";
import { DOC_URL } from "@config/global.config";
import FeedbackDialog from "@components/feedback-dialog";
import { Link } from "react-router-dom";
import { useUserActive } from "@hooks/use-user-active";

const { Header } = Layout;
const { Text } = Typography;
const HeaderSection = () => {
  const notificationTimerRef = useRef<NodeJS.Timeout>();
  const notivicationControllerRef = useRef<AbortController>();

  const userActiveRef = useRef(true);

  const dispatch = useDispatch<AppDispatch>();
  const [feedbackVisible, setFeedbackVisible] = useState(false);

  const [hasUnread, setHasUnread] = useState(false);

  const [notificationIdKey, setNotificationIdKey] = useState<string>();

  const notificationIdShowed = useRef<string[]>([]);

  const selectJobType = useSelector(
    (state: RootState) => state.editor.selectJobType
  );

  const navigate = useNavigate();
  const { data: userInfo } = useRequest(getLoginUserInfo);

  function logout() {
    logoutApi()
      .then(() => {
        removeLocalStorage(LoginEnum.Token);
        dispatch(deleteUser());
        navigate("/login");
      })
      .catch(() => {});
  }

  function onNavChange(e: {
    itemKey?: React.ReactText | undefined;
    domEvent?: MouseEvent | undefined;
    isOpen?: boolean | undefined;
  }) {
    const k = e.itemKey;
    if (
      k === JobGroupEnum.Utils ||
      k === JobGroupEnum.AntibodyDesign ||
      k === JobGroupEnum.AffinityOptimization ||
      k === JobGroupEnum.StructureModeling ||
      k === JobGroupEnum.ProteinDesign
    ) {
      return;
    }
    dispatch(setJobPanelVisible(true));
    if (e.itemKey) {
      dispatch(setSelectJobType(e.itemKey as JobTypeEnum));
    }
  }

  function clearRedDot() {
    cancelNotificationRedDotApi()
      .then(() => {
        setHasUnread(false);
      })
      .catch(() => {});
  }

  function onSupport() {
    const loginToken = getLocalStorage(LoginEnum.Token) as TokenModel;

    window.open(
      `${DOC_URL}/index.html?referer=${encodeURIComponent(
        window.location.href
      )}&token=${encodeURIComponent(encrypt(loginToken.token))}`,
      "_blank"
    );
  }

  function toHome() {
    navigate("/dashboard/projects");
  }

  function onFeedbackCancel() {
    setFeedbackVisible(false);
  }

  function onFeedbackSubmit() {
    setFeedbackVisible(false);
  }

  function openFeedbackDialog() {
    setFeedbackVisible(true);
  }

  function onDownload(
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    file_id: string
  ) {
    e.stopPropagation();
    e.preventDefault();
    downloadFileUrlApi([file_id])
      .then(({ code, data: { list } }) => {
        const [file] = list;
        if (code === 200 && file.download_url) {
          window.open(file.download_url, "_blank");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  async function getNotificationInfo() {
    try {
      const info = await getNotificationInfoApi(
        notificationIdKey ? notificationIdKey : undefined,
        notivicationControllerRef.current?.signal
      );

      setHasUnread(info.has_unread);

      setNotificationIdKey(info.last_id);

      if (info.notification_list) {
        console.log("notice-->", info);

        const patchNotifications = info.notification_list.filter((item) => {
          return !notificationIdShowed.current.includes(item.id);
        });

        const newIds = patchNotifications.map((item) => item.id);

        notificationIdShowed.current = [
          ...notificationIdShowed.current,
          ...newIds,
        ];

        patchNotifications.forEach((item) => {
          Notification.info({
            title: item.title,
            content: (
              <>
                <div style={{ maxWidth: "600px" }}>{item.message.text}</div>
                {item.message.c_type === "file_download" && (
                  <div style={{ marginTop: 8 }}>
                    <Text
                      link
                      onClick={(e) => onDownload(e, item.message.file_id)}
                    >
                      Download
                    </Text>
                  </div>
                )}
              </>
            ),
            duration: 0,
            onClose: () => {
              markNotificationReadApi(item.id)
                .then(() => {
                  notificationIdShowed.current =
                    notificationIdShowed.current.filter((id) => {
                      return id !== item.id;
                    });
                })
                .catch((e) => {
                  console.error(e);
                });
            },
            onClick: () => {
              markNotificationReadApi(item.id)
                .then(() => {
                  notificationIdShowed.current =
                    notificationIdShowed.current.filter((id) => {
                      return id !== item.id;
                    });
                })
                .catch((e) => {
                  console.error(e);
                });
            },
          });
        });

        notificationTimerRef.current = setTimeout(
          () => {
            getNotificationInfo();
          },
          userActiveRef.current ? 20000 : 2 * 60 * 1000
        );
      } else {
        notificationTimerRef.current = setTimeout(
          () => {
            getNotificationInfo();
          },
          userActiveRef.current ? 20000 : 2 * 60 * 1000
        );
      }
    } catch (e) {
      notificationTimerRef.current = setTimeout(
        () => {
          getNotificationInfo();
        },
        userActiveRef.current ? 20000 : 2 * 60 * 1000
      );
    }
  }

  useEffect(() => {
    notivicationControllerRef.current = new AbortController();

    getNotificationInfo();

    return () => {
      clearTimeout(notificationTimerRef.current);
      notivicationControllerRef.current?.abort();
    };
  }, []);

  useUserActive({
    onActive: () => {
      userActiveRef.current = true;
    },
    onInactive: () => {
      userActiveRef.current = false;
    },
  });

  return (
    <Header style={{ backgroundColor: "var(--semi-color-bg-1)" }}>
      <Nav
        header={{
          logo: (
            <img
              src={logoImage}
              style={{ cursor: "pointer" }}
              onClick={toHome}
            />
          ),
          text: (
            <span className={styles.headTitle} onClick={toHome}>
              GeoBiologics
            </span>
          ),
        }}
        mode="horizontal"
        footer={
          <>
            <Dropdown
              trigger={"click"}
              showTick
              // visible
              position={"bottomRight"}
              render={<NotificationScrollList />}
            >
              <div className={styles.btnWrapper}>
                <Tooltip content="Notifications">
                  <Button
                    theme="borderless"
                    icon={<NotificationsIcon />}
                    className={styles.iconBtn}
                    style={{ marginRight: "12px" }}
                    onClick={clearRedDot}
                  />
                </Tooltip>
                {hasUnread && <span className={styles.redDot}></span>}
              </div>
            </Dropdown>

            <Tooltip content="Support">
              <Button
                theme="borderless"
                icon={<SupportIcon />}
                className={styles.iconBtn}
                style={{ marginRight: "20px" }}
                onClick={onSupport}
              />
            </Tooltip>

            <Popover
              position="bottom"
              showArrow
              style={{
                padding: "0px",
              }}
              trigger="click"
              clickToHide
              content={
                <Dropdown.Menu className={styles.dropdownContainer}>
                  <Dropdown.Item>
                    <Link
                      className={styles.linkContainer}
                      to="/dashboard/profile"
                    >
                      <div className={styles.avatarContainer}>
                        <Avatar
                          color="red"
                          size="small"
                          src={userInfo?.data.avatar}
                        >
                          {userInfo?.data.username.slice(0, 2)}
                        </Avatar>
                        <div className={styles.right}>
                          <div>{userInfo?.data.username}</div>
                          <div className={styles.email}>
                            {userInfo?.data.email}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </Dropdown.Item>
                  <Divider style={{ margin: 0 }} />
                  <Dropdown.Item>
                    <Link
                      className={styles.linkContainer}
                      to="/dashboard/projects"
                    >
                      Projects
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link className={styles.linkContainer} to="/dashboard/jobs">
                      Jobs
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link
                      className={styles.linkContainer}
                      to="/dashboard/files"
                    >
                      Files
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={openFeedbackDialog}>
                    <div className={styles.linkContainer}>
                      Share your feedback
                    </div>
                  </Dropdown.Item>
                  <Divider style={{ margin: 0 }} />
                  <Dropdown.Item onClick={logout}>
                    <div
                      className={`${styles.signout} ${styles.linkContainer}`}
                    >
                      Sign Out
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              }
            >
              <Avatar color="red" size="small" src={userInfo?.data.avatar}>
                {userInfo?.data.username.slice(0, 2)}
              </Avatar>
            </Popover>
          </>
        }
        onClick={onNavChange}
        selectedKeys={selectJobType ? [selectJobType] : []}
      >
        <Nav.Sub
          itemKey={JobGroupEnum.Utils}
          text={JOB_GROUP_LABEL[JobGroupEnum.Utils]}
        >
          <Nav.Item
            itemKey={JobTypeEnum.AbNumbering}
            text={JOB_TYPE_LABEL[JobTypeEnum.AbNumbering]}
          />
          <Nav.Item
            itemKey={JobTypeEnum.ProteinStructureAlign}
            text={JOB_TYPE_LABEL[JobTypeEnum.ProteinStructureAlign]}
          />
        </Nav.Sub>
        <Nav.Sub
          dropdownStyle={{ fontWeight: "normal" }}
          itemKey={JobGroupEnum.StructureModeling}
          text={JOB_GROUP_LABEL[JobGroupEnum.StructureModeling]}
        >
          <Nav.Item
            itemKey={JobTypeEnum.GenStructPred}
            text={JOB_TYPE_LABEL[JobTypeEnum.GenStructPred]}
          />
          <Nav.Item
            itemKey={JobTypeEnum.AbStructPred}
            text={JOB_TYPE_LABEL[JobTypeEnum.AbStructPred]}
          />
          <Nav.Item
            itemKey={JobTypeEnum.AbAgDocking}
            text={JOB_TYPE_LABEL[JobTypeEnum.AbAgDocking]}
          />
          <Nav.Item
            itemKey={JobTypeEnum.NanobodyStructPred}
            text={JOB_TYPE_LABEL[JobTypeEnum.NanobodyStructPred]}
          />
          <Nav.Item
            itemKey={JobTypeEnum.TcrStructPred}
            text={JOB_TYPE_LABEL[JobTypeEnum.TcrStructPred]}
          />
          <Nav.Item
            itemKey={JobTypeEnum.EpitopePredict}
            text={JOB_TYPE_LABEL[JobTypeEnum.EpitopePredict]}
          />
        </Nav.Sub>
        <Nav.Sub
          dropdownStyle={{ fontWeight: "normal" }}
          itemKey={JobGroupEnum.AffinityOptimization}
          text={JOB_GROUP_LABEL[JobGroupEnum.AffinityOptimization]}
        >
          <Nav.Item
            itemKey={JobTypeEnum.AffinityOptim}
            text={JOB_TYPE_LABEL[JobTypeEnum.AffinityOptim]}
          />
        </Nav.Sub>
        <Nav.Sub
          dropdownStyle={{ fontWeight: "normal" }}
          itemKey={JobGroupEnum.AntibodyDesign}
          text={JOB_GROUP_LABEL[JobGroupEnum.AntibodyDesign]}
          data-sub
        >
          <Nav.Item
            itemKey={JobTypeEnum.OptimizationAntibody}
            text={JOB_TYPE_LABEL[JobTypeEnum.OptimizationAntibody]}
          />
          <Nav.Item
            itemKey={JobTypeEnum.DnAbDesign}
            text={JOB_TYPE_LABEL[JobTypeEnum.DnAbDesign]}
          />
          <Dropdown.Divider />
          <Dropdown.Title>Legacy</Dropdown.Title>
          <Nav.Item
            itemKey={JobTypeEnum.DevPred}
            text={JOB_TYPE_LABEL[JobTypeEnum.DevPred]}
          />
          <Nav.Item
            itemKey={JobTypeEnum.HumanPred}
            text={JOB_TYPE_LABEL[JobTypeEnum.HumanPred]}
          />
          <Nav.Item
            itemKey={JobTypeEnum.Humanization}
            text={JOB_TYPE_LABEL[JobTypeEnum.Humanization]}
          />
          {/* <Nav.Item
            itemKey={JobTypeEnum.AffinityPred}
            text={JOB_TYPE_LABEL[JobTypeEnum.AffinityPred]}
          /> */}
          {/* <Nav.Item
            itemKey={JobTypeEnum.AbLightChainRetrieval}
            text={JOB_TYPE_LABEL[JobTypeEnum.AbLightChainRetrieval]}
          /> */}
        </Nav.Sub>

        <Nav.Sub
          dropdownStyle={{ fontWeight: "normal" }}
          itemKey={JobGroupEnum.ProteinDesign}
          text={JOB_GROUP_LABEL[JobGroupEnum.ProteinDesign]}
        >
          <Dropdown.Title>Enzyme</Dropdown.Title>
          <Nav.Item
            itemKey={JobTypeEnum.EnzymeOptimize}
            text={JOB_TYPE_LABEL[JobTypeEnum.EnzymeOptimize]}
          />
          <Nav.Item
            itemKey={JobTypeEnum.EnzymeOptimizeTrain}
            text={JOB_TYPE_LABEL[JobTypeEnum.EnzymeOptimizeTrain]}
          />
          <Nav.Item
            itemKey={JobTypeEnum.EnzymeMining}
            text={JOB_TYPE_LABEL[JobTypeEnum.EnzymeMining]}
          />
          <Dropdown.Divider />
          <Dropdown.Title>Protein</Dropdown.Title>
          {/* <Nav.Item
            itemKey={JobTypeEnum.DevOptim}
            text={JOB_TYPE_LABEL[JobTypeEnum.DevOptim]}
          /> */}
          <Nav.Item
            itemKey={JobTypeEnum.ProteinMpnn}
            text={JOB_TYPE_LABEL[JobTypeEnum.ProteinMpnn]}
          />

          <Nav.Item
            itemKey={JobTypeEnum.MHC2BindPredict}
            text={JOB_TYPE_LABEL[JobTypeEnum.MHC2BindPredict]}
          />
        </Nav.Sub>
      </Nav>

      {feedbackVisible && (
        <FeedbackDialog cancel={onFeedbackCancel} submit={onFeedbackSubmit} />
      )}
    </Header>
  );
};

export default HeaderSection;
